(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var $scrollspy, $menu, $menuItems, $scrollSections, $bodyHtml, $mobileToggle,

//offsets/classes
headerOffsets, nodeCollection, activeNavClass, mobileToggleClass,

//magic controller
magicController, sceneCollection,

//module exports
windowResize, ScrollSpy;

var windowResize = function () {

    var callbacks = [],
        running = false;

    // fired on resize event
    function resize() {

        if (!running) {
            running = true;

            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(runCallbacks);
            } else {
                setTimeout(runCallbacks, 66);
            }
        }
    }

    // run the actual callbacks
    function runCallbacks() {

        callbacks.forEach(function (callback) {
            callback();
        });

        running = false;
    }

    // adds callback to loop
    function addCallback(callback) {

        if (callback) {
            callbacks.push(callback);
        }
    }

    return {
        // public method to add additional callback
        add: function add(callback) {
            if (!callbacks.length) {
                window.addEventListener('resize', resize);
            }
            addCallback(callback);
        }
    };
}();

ScrollSpy = {
    stickyMenuInit: function stickyMenuInit(headerOffsets) {

        var triggerElement, deviceOffset;

        triggerElement = $scrollspy[0];

        deviceOffset = window.innerWidth < 900 ? Math.ceil($menu.outerHeight() + 49) : 0; //49 accounts for padding on top of scrollnav section;

        sceneCollection['sticky-menu'] = new ScrollMagic.Scene({
            triggerElement: $scrollspy[0],
            triggerHook: 0,
            offset: headerOffsets,
            duration: Math.ceil($scrollspy.height()) - Math.ceil($menu.outerHeight()) + deviceOffset
        }).on('enter leave', function (e) {
            e.type === 'enter' ? $menu.addClass('is-sticky-top') : $menu.removeClass('is-sticky-top');
        }).on('end', function (e) {
            e.state === 'AFTER' ? $menu.addClass('is-sticky-bottom') : $menu.removeClass('is-sticky-bottom');
        })
        //.addIndicators()
        .addTo(magicController);
    },
    scrollToSection: function scrollToSection(sectionName) {

        var $sectionModule = nodeCollection[sectionName].module,
            sectionOffset = $sectionModule.offset().top,
            finalOffset = sectionOffset + headerOffsets * 2;

        if (window.helperUtils.isMobile) {
            finalOffset = finalOffset - 70;
        } //magic number is height of subnavigation;

        $bodyHtml.animate({
            scrollTop: finalOffset
        }, 500, function () {
            $sectionModule.focus();
        });
    },
    menuEventsInit: function menuEventsInit() {
        var _self = this;
        $menu.on('click.scrollspy', '.scrollspy-nav-link', function (ev) {

            ev.stopPropagation();
            ev.preventDefault();

            var sectionID = this.getAttribute('href');

            sectionID = sectionID.slice(1).toLowerCase();

            _self.scrollToSection(sectionID);
        });

        //mobile toggle
        $menu.on('click', '.js-nav-toggle', function (ev) {

            ev.preventDefault();
            ev.stopPropagation();

            if ($menu.hasClass(mobileToggleClass)) {
                $menu.removeClass(mobileToggleClass).find('.scrollspy-nav-list').attr('aria-hidden', 'true');

                $(this).attr('aria-expanded', 'false');
            } else {
                $menu.addClass(mobileToggleClass).find('.scrollspy-nav-list').attr('aria-hidden', 'false');

                $(this).attr('aria-expanded', 'true');
            }
        });
    },
    updateAriaStatus: function updateAriaStatus() {

        if (window.helperUtils.isMobile) {
            $menu.removeClass(mobileToggleClass).find('.scrollspy-nav-list').attr('aria-hidden', 'true').end().find('.js-nav-toggle').attr('aria-expanded', 'false');
        } else {
            $menu.addClass(mobileToggleClass).find('.scrollspy-nav-list').attr('aria-hidden', 'false').end().find('.js-nav-toggle').attr('aria-expanded', 'true');
        }
    },
    magicSceneInit: function magicSceneInit() {
        //
        var updateNavStatus,
            addMagicSceneSection,
            _self = this;

        //updating navigation
        updateNavStatus = function updateNavStatus(sectionName) {
            $menuItems.removeClass(activeNavClass).find('.scrollspy-nav-link').attr('aria-label', '');

            if (!!sectionName) {
                nodeCollection[sectionName].navItem.addClass(activeNavClass).find('.scrollspy-nav-link').attr('aria-label', 'Is Selected');
            }

            $mobileToggle.text(nodeCollection[sectionName].navItem.text());
            $menu.removeClass(mobileToggleClass);
        };

        addMagicSceneSection = function addMagicSceneSection(sectionNode) {

            var moduleName = sectionNode.getAttribute('id').toLowerCase();
            if (moduleName != "") {
                sceneCollection[moduleName + 'Scene'] = new ScrollMagic.Scene({
                    triggerElement: nodeCollection[moduleName].module[0],
                    duration: nodeCollection[moduleName].module.outerHeight()
                }).on('enter', function () {
                    updateNavStatus(moduleName);
                }).addTo(magicController);
            }
        };

        for (var i = 0; i < $scrollSections.length; i++) {
            addMagicSceneSection($scrollSections.eq(i)[0]);
        }

        //dont need sections anymore, have it garbage collected;
        addMagicSceneSection = null;
    },
    refreshBodyDimensions: function refreshBodyDimensions() {
        var headerOffsets,
            deviceOffset,
            resizeDuration,
            _self = this;

        headerOffsets = $('#site-header').outerHeight() * -1;

        deviceOffset = window.innerWidth < 900 ? Math.ceil($menu.outerHeight() + 49) : 0; //49 accounts for padding on top of scrollnav section;

        resizeDuration = Math.ceil($scrollspy.height()) - Math.ceil($menu.outerHeight()) - deviceOffset;

        if (resizeDuration < 0) {
            //this.disabledFeature();

            return;
        }

        sceneCollection['sticky-menu'].duration(resizeDuration).offset(headerOffsets);

        _self.refreshSectionDimensions();
    },
    refreshSectionDimensions: function refreshSectionDimensions() {

        var computeSectionDuration = function computeSectionDuration(moduleName) {

            sceneCollection[moduleName + 'Scene'].duration(nodeCollection[moduleName].module.outerHeight());
        };

        Object.keys(nodeCollection).forEach(function (key, index) {
            computeSectionDuration(key);
        });
    },
    windowResizeEvent: function windowResizeEvent() {
        var resizeMagicLogic;

        resizeMagicLogic = function resizeMagicLogic() {

            //if magic scene is disabled do nothing until it's activated;
            if (!sceneCollection['sticky-menu'].enabled()) {
                return;
            }
            this.refreshBodyDimensions();
        };

        windowResize.add(resizeMagicLogic.bind(this));
    },
    init: function init() {

        var populateNodeCollection;

        magicController = new ScrollMagic.Controller();;
        sceneCollection = {};

        //initialize elements;
        $scrollspy = $('.js-scrollspy');
        $menu = $scrollspy.find('.js-scrollspy-nav');
        $menuItems = $menu.find('.js-option');
        $scrollSections = $scrollspy.find('.js-scrollspy-section');
        $mobileToggle = $scrollspy.find('.js-nav-toggle');
        $bodyHtml = $('body,html');

        //variables
        activeNavClass = 'is-inview';
        mobileToggleClass = 'show-menu';
        headerOffsets = $('#site-header').outerHeight() * -1;
        nodeCollection = {};

        populateNodeCollection = function populateNodeCollection($sectionNode) {

            var sectionID = $sectionNode[0].getAttribute('id').toLowerCase(),
                $navNode = $menuItems.filter(function (index) {

                var hrefAttribute = $(this).find('.scrollspy-nav-link')[0].getAttribute('href');

                return sectionID === hrefAttribute.slice(1).toLowerCase();
            });

            nodeCollection[sectionID.toLowerCase()] = {
                module: $sectionNode,
                navItem: $navNode
            };
        };

        for (var i = $scrollSections.length - 1; i >= 0; i--) {
            populateNodeCollection($scrollSections.eq(i));
        }

        populateNodeCollection = null;

        //setup initial class for navigation, and update mobile default label
        $menuItems.eq(0).addClass(activeNavClass).find('.scrollspy-nav-link').attr('aria-label', 'Is Selected');

        $mobileToggle.text($menuItems.eq(0).find('.scrollspy-nav-link').text());

        this.stickyMenuInit(headerOffsets);

        this.menuEventsInit();

        this.magicSceneInit();

        this.updateAriaStatus();

        this.windowResizeEvent();
    }
};

module.exports = ScrollSpy;

},{}],2:[function(require,module,exports){
'use strict';

(function (global, undefined) {

	var $tabsArea = $('.js-tabs-area'),
	    $tabsNav,
	    $tabsNavLinks,
	    $tabsNavLabel,
	    $tabsDesignLine,
	    $tabsPanelsBody,
	    $tabsPanels,
	    $selectedTab,
	    slideDirection,
	    panelLength,
	    isAnimating,
	    currentIndex,
	    selectedTabIndex,
	    mobileState,
	    activeNavClass,
	    activePanelClass,
	    animationTimingDuration;

	if ($tabsArea.length === 0) {
		return;
	}

	$tabsNav = $tabsArea.find('.js-tabs-nav');
	$tabsNavLinks = $tabsNav.find('.js-tabs-nav-link');
	$tabsNavLabel = $tabsNav.find('.js-tabs-label');
	$tabsDesignLine = $tabsArea.find('.js-design-line');
	$tabsPanelsBody = $tabsArea.find('.js-tabs-panel-body');
	$tabsPanels = $tabsPanelsBody.find('.js-tabs-panel');
	panelLength = $tabsPanels.length;

	//Default Values
	currentIndex = 0;
	isAnimating = false;
	activeNavClass = 'is-active-tab';
	activePanelClass = 'is-active-panel';
	animationTimingDuration = 300;

	//Toggle display of Tabs in mobile
	$tabsNavLabel.on("click", function (ev) {
		ev.stopImmediatePropagation();
		$tabsNav.toggleClass('is-expanded');
	});

	//Attached event handler for nav links
	$tabsNav.on('click.tabsPanel', '.js-tabs-nav-link', function (ev) {
		ev.preventDefault();

		$selectedTab = $(this);
		selectedTabIndex = $tabsNavLinks.index($selectedTab);

		//We clicked the same link so do nothing
		if (currentIndex === selectedTabIndex || isAnimating) return;

		updateTabSelection($selectedTab);

		currentIndex < selectedTabIndex ? slideDirection = "left" : slideDirection = "right";

		updatePanelSelection($tabsPanels.eq(selectedTabIndex), slideDirection);

		//need to account for mobile
		//$tabsNavList.removeClass('is-expanded');
	});

	function updateTabSelection($tabSelected) {
		$tabsNavLinks.eq(currentIndex).removeClass(activeNavClass);
		$tabSelected.addClass(activeNavClass);

		//update mobile label 
		$tabsNavLabel.text($tabSelected.text());

		if (window.helperUtils.isMobile) {
			$tabsNav.removeClass('is-expanded');
		} else {
			slideDesignLine($tabSelected);
		}
	}

	function updatePanelSelection($panelSelected, slideDirection) {
		isAnimating = true;
		var $currentPanel = $tabsPanels.eq(currentIndex);

		$panelSelected.addClass('is-selected');

		slideDirection === "left" ? $tabsPanelsBody.addClass('is-animating-left') : $tabsPanelsBody.addClass('is-animating-right');

		$tabsPanelsBody.css('height', $currentPanel.outerHeight()).addClass('is-animating-out');

		$panelSelected.addClass('is-animating-in');

		//Timeout Delay should be in sync with entire duration of animation
		setTimeout(function animateHeight() {

			$tabsPanelsBody.animate({
				height: $panelSelected.outerHeight()
			}, animationTimingDuration, function resetClasses() {

				$tabsPanelsBody.css('height', '').removeClass('is-animating-out is-animating-left is-animating-right');

				$currentPanel.removeClass(activePanelClass);

				$panelSelected.removeClass('is-animating-in is-selected').addClass(activePanelClass);

				isAnimating = false;
			});
			currentIndex = selectedTabIndex;
		}, animationTimingDuration);
	}

	function slideDesignLine($tabSelected) {
		var tabWidth = $tabSelected.width(),
		    tabOffset = $tabSelected.position().left;

		$tabsDesignLine.css({
			"left": tabOffset,
			width: tabWidth + 'px'
		});
	}

	//Initialize default settings
	$(window).load(function () {
		$selectedTab = $tabsNavLinks.eq(0);
		slideDesignLine($selectedTab);
	});

	window.addEventListener("resize", resizeThrottler, false);

	var resizeTimeout;
	function resizeThrottler() {
		// ignore resize events as long as an actualResizeHandler execution is in the queue
		if (!resizeTimeout) {
			resizeTimeout = setTimeout(function () {
				resizeTimeout = null;
				slideDesignLine($selectedTab);

				// The actualResizeHandler will execute at a rate of 15fps
			}, 66);
		}
	}
})(undefined);

},{}],3:[function(require,module,exports){
'use strict';

require('./tabArea.js');

$(document).ready(function () {

    //Related Professional 'See All'
    $relatedProfessionalInitialCount = $('.contacts').data("initialcount");
    $relatedProfessionalTotalList = $('.contacts').find('li');

    var count = $relatedProfessionalInitialCount - 1;
    $listitems = $('.contacts ul li:gt(' + count + ')');

    $viewMoreButton = $('.contacts__view-more');

    if ($relatedProfessionalInitialCount == 0) $relatedProfessionalInitialCount = 6;

    $listitems.hide();
});

},{"./tabArea.js":4}],4:[function(require,module,exports){
'use strict';

require('./features/tabs-area.js');
var ScrollSpy = require('./features/scrollspy.js');

$(document).ready(function () {

    var $container = $('.js-tabs-area').length ? $('.js-tabs-area') : $('.scrollspy-sections');
    if (!$container.length) return;

    function appendData($list, url) {
        $.ajax({
            url: url
        }).done(function (data) {
            $list.append(data);

            var $items = $list.children('li');
            $items.slideDown();

            var $last = $items.last();
            var dataNextUrl = $last.attr('data-next-url');

            var $button = $list.next('button');
            if (dataNextUrl) {
                $button.show();
            } else {
                $button.hide();
            }
            //force scrollspy to update after content is loaded
            setTimeout(ScrollSpy.refreshBodyDimensions, 200);
        });
    }

    var $listArticles = $container.find('.list-articles');
    $listArticles.each(function () {
        var $this = $(this);
        var url = $this.attr('data-url');
        appendData($this, url);
    });

    var $viewMoreButtons = $container.find('.js-button-expand');
    $viewMoreButtons.click(function () {
        var $this = $(this);

        var $list = $this.prev('ul');
        var $last = $list.children('li').last();
        var url = $last.attr('data-next-url');

        appendData($list, url);
    });
});

},{"./features/scrollspy.js":1,"./features/tabs-area.js":2}]},{},[3]);
